import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Page not found" />
      <div id="main-content">
        <div className="container" style={{ paddingTop: '176px' }}>
          <div id="content-area" className="clearfix">
            <div id="left-area">
              <article id="post-0" className="et_pb_post not_found">
                <div className="entry">
                  <h1 className="not-found-title">Not Found</h1>
                  <p>The page you requested could not be found. Please try using the navigation bar above.</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
